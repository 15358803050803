import { MouseEvent, useEffect, useState } from "react";
import moment from "moment";

import { Card, CardLoader, UserAvatar } from "../../../../components/layout"
import { useAppModal } from "../../../../components/layout/AppModal/utils";
import useAppActions from "../../../../redux/actions/app";
import { capitalizeKey } from "../../../../utils/strings";
import { User } from "../../../../requests/users/types";
import useUsers from "../../../../requests/users";
import { FilteredUserData } from "./types";
import { Link } from "react-router-dom";
import { accountPaths } from "../../../../routes/accounts/paths";



type Props = {
  user: User | null;
}

function UserData({ user }: Props) {
  const [formattedUser, setFormattedUser] = useState<any>();
  const { setAppModal, closeModal } = useAppModal();
  const { setAppLoading } = useAppActions();
  const { archiveUser, unArchiveUser } = useUsers();


  /******************************/
  /** Generate Custom User Data */
  const formatUserData = (user: User) => {
    const filteredData = userKeys.reduce((obj, rawKey) => {
      const rawValue = user[rawKey];
      const { key, value } = filterUserData(rawKey, rawValue) as any;
      obj[key] = value;
      return obj;
    }, {} as any);
    setFormattedUser(filteredData);
  }

  /********************************/
  /** Archive / Unarchive account */
  const toggleArchive = (e: MouseEvent, user: User) => {
    e.preventDefault();
    setAppLoading(true);
    if (user.archived) {
      unArchiveUser(user.id)
        .then(() => {
          window.location.reload();
        })
        .catch(() => null);
    } else {
      archiveUser(user.id)
        .then(() => {
          window.location.reload();
        })
        .catch(() => null);
    }
  }

  /**********************************************/
  /** Confirmation for sending activation email */
  const confirmToggleArchive = (e: MouseEvent, user: User) => {
    e.preventDefault();
    setAppModal({
      title: "Are you sure?",
      children: (
        `This would ${user.archived ? "unarchive" : "archive"}${" "
        }this account.${"\n"}Do you want to proceed?
      `),
      controls: (
        <div className="modal-controls">
          <button
            className="button white-black"
            onClick={closeModal}
          >
            Cancel
          </button>
          <button
            className="button"
            onClick={(e) => toggleArchive(e, user)}
          >
            Yes, {user.archived ? "Unarchive" : "Archive"}
          </button>
        </div>
      )
    });
  }


  useEffect(() => {
    if (!user) return;
    formatUserData(user);
  }, [user])



  return (
    !(user && formattedUser) ? (
      <CardLoader />
    ) : (
      <Card>
        <div className="heading">
          <div className="card-title">
            User
          </div>
          <UserAvatar user={user} />
        </div>
        <ul className="detail-list flex column">
          {Object.entries(formattedUser).map(([key, value]) =>
            <li className="list-item" key={key}>
              <div className="key">
                {key}
              </div>
              <div className="value">
                {value ? value as any : "-"}
              </div>
            </li>
          )}

        </ul>
        <div className="flex ">
          <button
            className="button sm auto-width mt-2"
            onClick={(e) => confirmToggleArchive(e, user)}
          >
            {user.archived ? "Unarchive" : "Archive"} Account
          </button>
        </div>
      </Card>
    )
  );
}

export default UserData;



// Fields to show on component
type UserKey = keyof User;
const userKeys: UserKey[] = [
  "username", "emailVerified", "nickName",
  "firstName", "middleName", "lastName",
  "familyRole", "dob", "archived", "active",
  "createdAt", "lastLogin", "onCardProgram",
  "archivedBy"
]

/***************************************/
/** Format output data for User fields */
const filterUserData = (
  rawKey: UserKey,
  rawValue: any
): FilteredUserData => {
  let key = capitalizeKey<User>(rawKey);
  let value = rawValue ? rawValue.toString() : "";
  switch (rawKey) {
    case "dob":
      key = "Date of Birth";
      const dobValue = rawValue ? (
        moment(rawValue).format("Do MMMM, YYYY")
      ) : "-";
      return {
        key,
        value: dobValue
      }
    case "familyRole":
      const uppercaseValue = rawValue.charAt(0).toUpperCase() + rawValue.slice(1);
      const roleName = rawValue === "admin" ? "Parent" : "Learner";
      return {
        key,
        value: `Family ${uppercaseValue} (${roleName})`
      };
    case "emailVerified":
      return {
        key,
        value: rawValue ? "Yes" : "No"
      }
    case "onCardProgram":
      return {
        key,
        value: rawValue ? "Yes" : "No"
      }
    case "archived":
      key = "Archived"
      return {
        key,
        value: rawValue ? "Yes" : "No"
      }
    case "archivedBy":
      return {
        key,
        value: rawValue ? (
          <Link
            className="link"
            to={accountPaths.userDetail(rawValue.id)}
          >
            {rawValue.firstName} {rawValue.lastName}
          </Link>
        ) : "-"
      }
    case "createdAt":
      key = "Signed Up On"
      const createdAtValue = rawValue ? (
        moment(rawValue).format("Do MMMM, YYYY - hh:mma")
      ) : "-";
      return {
        key,
        value: createdAtValue
      }
    case "lastLogin":
      const lastLoginValue = rawValue ? (
        moment(rawValue).format("Do MMMM, YYYY - hh:mma")
      ) : "-";
      return {
        key,
        value: lastLoginValue
      }
    default:
      return {
        key,
        value
      }
  }
}