import { useEffect, useState } from "react";

import { PageHeader, Table } from "../../../components/layout";
import { useQueryParams } from "../../../utils/components";
import { SONAEvent } from "../../../requests/events/types";
import { PaginatedResponse } from "../../../types";
import useEvents from "../../../requests/events";
import useEventListUtils from "./utils";
import Styles from "./styles";



function EventList() {
  const { locationSearch, queryParams, updateFilters } = useQueryParams();
  const pageSize = queryParams.get("pageSize") || "";
  const page = queryParams.get("page") || "1";
  const search = queryParams.get("search") || "";
  const orderBy = queryParams.get("orderBy") || "";
  const { tableColumns } = useEventListUtils();
  const { listEvents } = useEvents();
  const [tableData, setTableData] = useState<PaginatedResponse<SONAEvent>>();
  const [loading, setLoading] = useState<boolean>(false);


  /*******************/
  /** Get Event List */
  const retrieveEvents = async () => {
    const params = {
      search,
      page,
      pageSize,
      orderBy
    }
    setLoading(true);
    try {
      const resData = await listEvents(params);
      setTableData(resData);
    } catch { }
    finally { setLoading(false) }
  }


  useEffect(() => {
    retrieveEvents();
  }, [locationSearch])



  return (
    <Styles className="root-content">
      {/* Header */}
      <PageHeader showSearch />

      {/* Main Content */}
      <div className="page-wrapper flex column">
        <Table
          {...tableData}
          columns={tableColumns}
          loading={loading}
        />
      </div>
    </Styles>
  );
}

export default EventList;