export const missionRoot = "/missions";



const missionId = ":missionId";

export const missionPaths = {
  PUBLISHED: `${missionRoot}/published`,
  DRAFT: `${missionRoot}/drafts`,
  CREATE: `${missionRoot}/create`,
  EDIT: `${missionRoot}/${missionId}`,
  edit: (id: number | string) => `${missionRoot}/${id}`
}