export const eventRoot = "/events";


const eventId = ":eventId";

export const eventPaths = {
  LIST: `${eventRoot}/list`,
  DETAIL: `${eventRoot}/${eventId}`,
  detail: (eventId: number | string) => (
    `${eventRoot}/${eventId}`
  )
}