import { SelectFieldOption } from "../../components/forms/SelectField";
import { SubscriptionDiscount } from "../subscriptionDiscount/types";
import { PaymentsUser } from "../users/types";
import { PlanPrice } from "../plans/types";



export type SubscriptionStatus =
  | "trial"
  | "active"
  | "cancelled"
  | "overdue"
  | "expired";


export type RecurringInfo = {
  project_id: number;
  recurring: {
    id: number;
    type: string;
    last_payment_at: Date;
    valid_thru: Date;
    status: string;
    description: string;
    currency: string;
  }
}

export type Subscription = {
  id: string;
  manager: PaymentsUser;
  managerId: string;
  planPrice?: PlanPrice;
  planPriceId?: number;
  periodStart: Date;
  periodEnd?: Date;
  freeTrialStart?: Date;
  freeTrialEnd?: Date;
  lastBillingPeriod?: Date;
  nextBillingPeriod?: Date;
  expired: boolean;
  status: SubscriptionStatus;
  discounts?: SubscriptionDiscount[];
  billingAttempts: number;
  recurringId: string;
  recurringInfo?: RecurringInfo;
  createdAt: Date;
  updatedAt: Date;
}

interface SubscriptionStatusOption extends SelectFieldOption {
  value: SubscriptionStatus | "";
}

export const SubscriptionStatuses: SubscriptionStatusOption[] = [
  {
    value: "",
    label: "All Statuses"
  },
  {
    value: "trial",
    label: "Trial"
  },
  {
    value: "active",
    label: "Active"
  },
  {
    value: "overdue",
    label: "Overdue"
  },
  {
    value: "expired",
    label: "Expired"
  },
  {
    value: "cancelled",
    label: "Cancelled"
  }
]