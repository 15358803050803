import { Link } from "react-router-dom";
import moment from "moment";

import { TableColumn } from "../../../components/layout/Table/types";
import { SONAEvent } from "../../../requests/events/types";
import { eventPaths } from "../../../routes/events/paths";
import { dateAndTimeFormat } from "../../../utils/dates";
import { optionalString } from "../../../utils/strings";



const useEventListUtils = () => {
  const tableColumns: TableColumn[] = [
    {
      title: "Name",
      key: "name",
      orderByKey: "name",
      content: (event: SONAEvent) => (
        <Link
          className="table-title"
          to={eventPaths.detail(event.id)}
        >
          {event.name}
        </Link>
      )
    },
    {
      title: "Email / Username",
      key: "username",
      orderByKey: "username",
    },
    {
      title: "Device",
      key: "deviceInfo",
      orderByKey: "deviceInfo",
      content: (event: SONAEvent) => (
        optionalString(event.deviceInfo)
      )
    },
    {
      title: "Browser",
      key: "browserInfo",
      orderByKey: "browserInfo",
      content: (event: SONAEvent) => (
        optionalString(event.browserInfo)
      )
    },
    {
      title: "Created At",
      key: "createdAt",
      orderByKey: "createdAt",
      content: (event: SONAEvent) => (
        moment(event.createdAt).isValid()
          ? moment(event.createdAt).format(dateAndTimeFormat)
          : "-"
      )
    }
  ]


  return {
    tableColumns
  }
}

export default useEventListUtils;