import { affirmationPaths } from "./affirmations/paths";
import { collabPaths } from "./collaborators/paths";
import { dashboardPaths } from "./dashboard/paths";
import Collaborators from "../pages/Collaborators";
import Affirmations from "../pages/Affirmations";
import { accountPaths } from "./accounts/paths";
import { missionPaths } from "./missions/paths";
import { profilePaths } from "./profile/paths";
import { billingPaths } from "./billing/paths";
import { offersPaths } from "./offers/paths";
import { assetPaths } from "./assets/paths";
import { emailPaths } from "./emails/paths";
import Dashboard from "../pages/Dashboard";
import Accounts from "../pages/Accounts";
import Missions from "../pages/Missions";
import Billing from "../pages/Billing";
import Profile from "../pages/Profile";
import Assets from "../pages/Assets";
import Emails from "../pages/Emails";
import Offers from "../pages/Offers";
import { RouteType } from "./types";
import { rootPaths } from "./paths";
import Events from "../pages/Events";
import { eventPaths } from "./events/paths";



/****************/
/** Root Routes */
export const rootRoutes: RouteType[] = [
  {
    path: rootPaths.DASHBOARD,
    element: <Dashboard />,
    redirect: dashboardPaths.EVENTS
  },
  {
    path: rootPaths.ACCOUNTS,
    element: <Accounts />,
    redirect: accountPaths.USERS
  },
  {
    path: rootPaths.AFFIRMATIONS,
    element: <Affirmations />,
    redirect: affirmationPaths.PUBLISHED
  },
  {
    path: rootPaths.ASSETS,
    element: <Assets />,
    redirect: assetPaths.TREES
  },
  {
    path: rootPaths.BILLING,
    element: <Billing />,
    redirect: billingPaths.LIST_PLANS
  },
  {
    path: rootPaths.COLLABORATORS,
    element: <Collaborators />,
    redirect: collabPaths.LIST
  },
  {
    path: rootPaths.EMAILS,
    element: <Emails />,
    redirect: emailPaths.LIST
  },
  {
    path: rootPaths.MISSIONS,
    element: <Missions />,
    redirect: missionPaths.PUBLISHED
  },
  {
    path: rootPaths.OFFERS,
    element: <Offers />,
    redirect: offersPaths.METRICS
  },
  {
    path: rootPaths.EVENTS,
    element: <Events />,
    redirect: eventPaths.LIST
  },
  {
    path: rootPaths.PROFILE,
    element: <Profile />,
    redirect: profilePaths.PERSONAL
  }
]