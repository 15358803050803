import { Link } from "react-router-dom";
import moment from "moment";

import { ModelDetailData } from "../../../components/layout/ModelDetail";
import { accountPaths } from "../../../routes/accounts/paths";
import { SONAEvent } from "../../../requests/events/types";
import { dateAndTimeFormat } from "../../../utils/dates";
import { optionalString } from "../../../utils/strings";
import { PrettyJson } from "../../../components/layout";



export const useEventDetailUtils = () => {
  const buildEventData = (event: SONAEvent) => {
    const {
      name, description, userId, username, createdAt,
      deviceInfo, browserInfo, metadata
    } = event;
    const modelData: ModelDetailData[] = [
      {
        name: "Name",
        description: name
      },

      {
        name: "Description",
        description: optionalString(description)
      },
      {
        name: "Device Info",
        description: optionalString(deviceInfo)
      },
      {
        name: "Browser Info",
        description: optionalString(browserInfo)
      },
      {
        name: "Created At",
        description: (
          <div className="grey-text">
            {moment(createdAt).isValid()
              ? moment(createdAt).format(dateAndTimeFormat)
              : "-"
            }
          </div>
        )
      },
      {
        name: userId ? "User" : "Email",
        description: (
          userId ? (
            <Link
              className="link underline"
              to={accountPaths.userDetail(userId)}
            >
              {username}
            </Link>
          ) : (
            username
          )
        )
      },
      {
        name: "Metadata",
        description: (
          <PrettyJson
            json={metadata || ""}
            title="Event Metadata"
          />
        )
      }
    ]

    return modelData;
  }


  return {
    buildEventData
  }
}