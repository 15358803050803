import { PersistGate } from "redux-persist/integration/react";
import "react-toastify/dist/ReactToastify.css";
import { Provider } from "react-redux";
import {
  BrowserRouter as Router, Routes, Route
} from "react-router-dom";

import { ModalProvider } from "../components/layout/AppModal/utils";
import { AuthRoute, NonAuthRoute } from "../components/auth";
import { affirmationRoutes } from "../routes/affirmations";
import { collabRoutes } from "../routes/collaborators";
import { dashboardRoutes } from "../routes/dashboard";
import { accountRoutes } from "../routes/accounts";
import { ScrollToTop } from "../components/layout";
import { missionRoutes } from "../routes/missions";
import { store, persistor } from "../redux/store";
import GlobalStyles from "../styles/GlobalStyles";
import { nonAuthRoutes } from "../routes/nonAuth";
import { billingRoutes } from "../routes/billing";
import { profileRoutes } from "../routes/profile";
import { eventRoutes } from "../routes/events";
import { emailRoutes } from "../routes/emails";
import { offerRoutes } from "../routes/offers";
import { assetRoutes } from "../routes/assets";
import { rootPaths } from "../routes/paths";
import { rootRoutes } from "../routes";
import { mapRoutes } from "./utils";
import {
  Home, Page404,
  Accounts, Affirmations, Assets,
  Billing, Collaborators, Dashboard,
  Emails, Events, Missions, Offers,
  Profile,
} from "../pages";



function App() {
  return (
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <ModalProvider>
          <Router>
            <ScrollToTop />
            <GlobalStyles />
            <Routes>
              <Route path={rootPaths.HOMEPAGE} element={<Home />} />
              {/*######################*/}
              {/* AUTHENTICATED ROUTES */}
              <Route element={<AuthRoute />}>
                {mapRoutes(rootRoutes)}

                {/* Dashboard */}
                <Route element={<Dashboard />}>
                  {mapRoutes(dashboardRoutes)}
                </Route>

                {/* Accounts */}
                <Route element={<Accounts />}>
                  {mapRoutes(accountRoutes)}
                </Route>

                {/* Assets */}
                <Route element={<Assets />}>
                  {mapRoutes(assetRoutes)}
                </Route>

                {/* Missions */}
                <Route element={<Missions />}>
                  {mapRoutes(missionRoutes)}
                </Route>

                {/* Affirmations */}
                <Route element={<Affirmations />}>
                  {mapRoutes(affirmationRoutes)}
                </Route>

                {/* Billing */}
                <Route element={<Billing />}>
                  {mapRoutes(billingRoutes)}
                </Route>

                {/* Emails */}
                <Route element={<Emails />}>
                  {mapRoutes(emailRoutes)}
                </Route>

                {/* Offers */}
                <Route element={<Offers />}>
                  {mapRoutes(offerRoutes)}
                </Route>

                {/* Emails */}
                <Route element={<Events />}>
                  {mapRoutes(eventRoutes)}
                </Route>

                {/* Collaborators */}
                <Route element={<Collaborators />}>
                  {mapRoutes(collabRoutes)}
                </Route>

                {/* Profile */}
                <Route element={<Profile />}>
                  {mapRoutes(profileRoutes)}
                </Route>
              </Route>

              {/*##########################*/}
              {/* NON-AUTHENTICATED ROUTES */}
              <Route element={<NonAuthRoute />}>
                {mapRoutes(nonAuthRoutes)}
              </Route>

              {/* Page Not Found */}
              <Route path="*" element={<Page404 />} />
            </Routes>
          </Router>
        </ModalProvider>
      </PersistGate>
    </Provider>
  );
}

export default App;