import { ChangeEvent, useEffect, useState } from "react";
import { Link } from "react-router-dom";

import { Email, EmailStatusOptions } from "../../../requests/emails/types";
import { Loader, PageHeader, Table } from "../../../components/layout";
import filter from "../../../assets/vector-images/generic/filter.svg";
import { useQueryParams } from "../../../utils/components";
import { emailPaths } from "../../../routes/emails/paths";
import { SelectField } from "../../../components/forms";
import { PaginatedResponse } from "../../../types";
import useEmails from "../../../requests/emails";
import { useInput } from "../../../utils/forms";
import useEmailListUtils from "./utils";
import Styles from "./styles";



function EmailList() {
  const { locationSearch, queryParams, updateFilters } = useQueryParams();
  const pageSize = queryParams.get("pageSize") || "";
  const page = queryParams.get("page") || "1";
  const search = queryParams.get("search") || "";
  const status = queryParams.get("status") || "";
  const orderBy = queryParams.get("orderBy") || "";
  const { listEmails } = useEmails();
  const { tableColumns } = useEmailListUtils();
  const [tableData, setTableData] = useState<PaginatedResponse<Email>>();
  const [loading, setLoading] = useState<boolean>(false);
  const {
    value: statusValue,
    setValue: setStatusValue,
  } = useInput({
    initialValue: status ? status : "",
    selectValues: EmailStatusOptions.map(option => option.value)
  });


  /***********************/
  /** Update Staus Value */
  const updateRegion = (e: ChangeEvent<HTMLSelectElement>) => {
    const value = e.target.value;
    setStatusValue(value);
    updateFilters("status", value);
  }

  /*******************/
  /** Get Email List */
  const getData = () => {
    const params = {
      search,
      status,
      page,
      pageSize,
      orderBy
    }
    setLoading(true);
    listEmails(params)
      .then((res) => {
        setTableData(res);
      })
      .catch(() => null)
      .finally(() => {
        setLoading(false);
      });
  }


  useEffect(() => {
    getData();
  }, [locationSearch])



  return (
    <Styles className="root-content">
      {/* Header */}
      <PageHeader showSearch>
        <SelectField
          options={EmailStatusOptions}
          placeholder="All Statuses"
          icon={filter}
          value={statusValue}
          onChange={updateRegion}
        />
        <Link
          to={emailPaths.CREATE}
          className="button sm mid-radius">
          Send Email
        </Link>
      </PageHeader>

      {/* Main Content */}
      <div className="page-wrapper flex column">
        <Table
          {...tableData}
          columns={tableColumns}
          loading={loading}
        />
      </div>
    </Styles>
  );
}

export default EmailList;