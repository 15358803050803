import axios from "axios";

import { ErrorResponse, PaginatedResponse } from "../../types";
import { TribeExternalTxn } from "./types";



/*  #################################### */
/** ## TRIBE EXTERNAL TXN API REQUESTS # */
/*  #################################### */
const useTribeExternalTxns = () => {
  /******************************/
  /** List Tribe External Transactions */
  const listTribeExternalTxns = (params: Record<string, string>) => {
    return new Promise<PaginatedResponse<TribeExternalTxn>>(
      (resolve, reject) => {
        axios({
          url: `${process.env.REACT_APP_SONA_PAYMENTS_API_URL
            }/v2/tribe-external-txns`,
          params
        }).then((res) => {
          const resData = res.data as PaginatedResponse<TribeExternalTxn>;
          resolve(resData);
        }).catch((error) => {
          reject(error as ErrorResponse);
        });
      });
  }


  return {
    listTribeExternalTxns
  }
}

export default useTribeExternalTxns;