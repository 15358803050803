import { ChangeEvent, useEffect, useState } from "react";

import { PlanTypes, PlanIntervals } from "../../../../requests/plans/types";
import filter from "../../../../assets/vector-images/generic/filter.svg";
import useSubscriptions from "../../../../requests/subscriptions";
import { PageHeader, Table } from "../../../../components/layout";
import { useQueryParams } from "../../../../utils/components";
import { SelectField } from "../../../../components/forms";
import { PaginatedResponse } from "../../../../types";
import { useInput } from "../../../../utils/forms";
import {
  Subscription, SubscriptionStatuses
} from "../../../../requests/subscriptions/types";
import useSubscriptionListUtils from "./utils";
import Styles from "./styles";



function SubscriptionList() {
  const { locationSearch, queryParams, updateFilters } = useQueryParams();
  const pageSize = queryParams.get("pageSize") || "";
  const search = queryParams.get("search") || "";
  const page = queryParams.get("page") || "1";
  const orderBy = queryParams.get("orderBy") || "";
  const planType = queryParams.get("planType") || "";
  const planInterval = queryParams.get("planInterval") || "";
  const status = queryParams.get("status") || "";
  const { listSubscriptions } = useSubscriptions();
  const { tableColumns } = useSubscriptionListUtils();
  const [loading, setLoading] = useState<boolean>(true);
  const [tableData, setTableData] = useState<
    PaginatedResponse<Subscription>
  >();
  const {
    value: planTypeValue,
    setValue: setPlanType,
  } = useInput({
    initialValue: planType,
    selectValues: PlanTypes.map(option => option.value)
  });
  const {
    value: planIntervalValue,
    setValue: setPlanInterval,
  } = useInput({
    initialValue: planInterval,
    selectValues: PlanIntervals.map(option => option.value)
  });
  const {
    value: statusValue,
    setValue: setStatus,
  } = useInput({
    initialValue: status,
    selectValues: SubscriptionStatuses.map(option => option.value)
  });


  const updatePlanType = (e: ChangeEvent<HTMLSelectElement>) => {
    const value = e.target.value;
    setPlanType(value);
    updateFilters("planType", value);
  }

  const updatePlanInterval = (e: ChangeEvent<HTMLSelectElement>) => {
    const value = e.target.value;
    setPlanInterval(value);
    updateFilters("planInterval", value);
  }

  const updateStatus = (e: ChangeEvent<HTMLSelectElement>) => {
    const value = e.target.value;
    setStatus(value);
    updateFilters("status", value);
  }



  /***********************/
  /** List Subscriptions */
  const getSubscriptions = async () => {
    setLoading(true);
    const params = {
      search,
      page,
      pageSize,
      orderBy,
      status,
      planType,
      planInterval
    }
    try {
      const resData = await listSubscriptions(params);
      setTableData(resData);
    } catch { }
    finally { setLoading(false) }
  }


  useEffect(() => {
    getSubscriptions();
  }, [locationSearch])



  return (
    <Styles className="root-content">
      {/* Header */}
      <PageHeader showSearch>
        <div className="flex align-center">
          <SelectField
            options={PlanTypes}
            placeholder="All Plans"
            icon={filter}
            value={planTypeValue}
            onChange={updatePlanType}
          />
          <SelectField
            options={PlanIntervals}
            placeholder="All Plan Intervals"
            icon={filter}
            value={planIntervalValue}
            onChange={updatePlanInterval}
          />
          <SelectField
            options={SubscriptionStatuses}
            placeholder="All Statuses"
            icon={filter}
            value={statusValue}
            onChange={updateStatus}
          />
        </div>
      </PageHeader>

      {/* Main Content */}
      <div className="page-wrapper">
        <Table
          {...tableData}
          columns={tableColumns}
          loading={loading}
        />
      </div>
    </Styles>
  );
}

export default SubscriptionList;