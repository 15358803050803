import { SelectFieldOption } from "../../components/forms/SelectField";
import { currencySymbol } from "../../utils/strings";
import { decimalPrice } from "../../utils/numbers";
import usePlans from "../../requests/plans";



export const useBillingUtils = () => {
  const { listPlans } = usePlans();
  const getPlanPriceOptions = async () => {
    try {
      const plans = await listPlans({})
      const planPrices = [];
      for (const plan of plans) {
        for (const planPrice of plan.planPrices) {
          if (planPrice.store !== "sona") continue;
          const planPriceOption: SelectFieldOption = {
            value: `${planPrice.id}`,
            label: (
              `${plan.name} (${plan.region}) - ${""
              }${currencySymbol(planPrice.currency)
              }${decimalPrice(planPrice.amount)}${""
              } / ${planPrice.interval}`
            )
          }
          planPrices.push(planPriceOption);
        }
      }
      return planPrices;
    } catch {
      return []
    }
  }


  return {
    getPlanPriceOptions
  }
}