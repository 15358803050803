import { RouteType } from "../types";
import { eventPaths } from "./paths";
import {
  EventList,
  EventDetail
} from "../../pages/Events/pages";



/*****************/
/** Event Routes */
export const eventRoutes: RouteType[] = [
  {
    path: eventPaths.LIST,
    element: <EventList />
  },
  {
    path: eventPaths.DETAIL,
    element: <EventDetail />
  }
]