import { useEffect, useState } from "react";
import moment from "moment";

import { Subscription } from "../../../../requests/subscriptions/types";
import { capitalizeKey } from "../../../../utils/strings";
import { decimalPrice } from "../../../../utils/numbers";
import { Plan } from "../../../../requests/plans/types";
import { FilteredUserData } from "./types";
import { Link } from "react-router-dom";
import { billingPaths } from "../../../../routes/billing/paths";



type Props = {
  subscription: Subscription | null;
}

function SubscriptionData({ subscription }: Props) {
  const [formattedSubscription, setFormattedSubscription] = useState<any>();

  /*****************************/
  /** Format Subscription Data */
  const formatSubscriptionData = (subscription: Subscription) => {
    if (!subscription.planPrice) {
      setFormattedSubscription({});
      return;
    }
    const planData = planKeys.reduce((obj, rawKey) => {
      const rawValue = subscription.planPrice?.plan[rawKey];
      const { key, value } = filterSubscriptionData(
        rawKey,
        rawValue,
        subscription
      ) as any;
      obj[key] = value;
      return obj;
    }, {} as any);

    const filteredData = {
      ...planData,
    };
    setFormattedSubscription(filteredData);
  };

  useEffect(() => {
    if (!subscription) return;
    formatSubscriptionData(subscription);
  }, [subscription])



  return (
    !(subscription && formattedSubscription) ? (
      null
    ) : (
      <>
        <ul className="detail-list flex column">
          {Object.entries(formattedSubscription).map(([key, value]) =>
            <li className="list-item" key={key}>
              <div className="key">
                {key}
              </div>
              <div className="value">
                {value ? value as any : "-"}
              </div>
            </li>
          )}
        </ul>
      </>
    )
  );
}

export default SubscriptionData;



// Fields to show on component
type PlanKey = keyof Plan;
const planKeys: PlanKey[] = [
  "maxUsers", "maxCards", "name",
  "active", "updatedAt", "createdAt"
]

// /***********************************************/
/** Format output data for Subscription fields */
const filterSubscriptionData = (
  rawKey: PlanKey,
  rawValue: any,
  subscription: Subscription
): FilteredUserData => {
  let key = capitalizeKey<any>(rawKey);
  let value = rawValue ? rawValue.toString() : "-";
  switch (rawKey) {
    case "name":
      key = "Subscription";
      const currency = (subscription.planPrice?.plan.region === "UK")
        ? "£"
        : "$";
      const amount = `${currency}${decimalPrice(subscription.planPrice?.amount)}`;
      const planInterval = subscription.planPrice?.interval;
      return {
        key,
        value: (
          <Link
            className="link"
            to={billingPaths.subscriptionDetail(subscription.id)}
          >
            {value} Plan -  {amount} / {planInterval}
          </Link>
        )
      }
    case "active":
      key = "Subscription Manager";
      const { firstName, lastName } = subscription.manager;
      return {
        key,
        value: `${firstName} ${lastName}`
      }
    case "updatedAt":
      key = "Subscription Expiry";
      return {
        key,
        value: subscription.periodEnd ? (
          moment(subscription.periodEnd).format("Do MMMM, YYYY - hh:mma")
        ) : ""
      }

    default: {
      return {
        key,
        value
      }
    }
  }
}