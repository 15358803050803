import { useParams } from "react-router-dom";
import { useCallback, useEffect, useState } from "react";

import { TribeExternalTxn } from "../../../../requests/tribeExternalTxns/types";
import { ModelDetailData } from "../../../../components/layout/ModelDetail";
import useTribeExternalTxns from "../../../../requests/tribeExternalTxns";
import Spinner from "../../../../components/layout/Spinner";
import { Tribe } from "../../../../requests/tribe/types";
import { PaginatedResponse } from "../../../../types";
import { URLParams } from "../../../../constants";
import useTribe from "../../../../requests/tribe";
import useTribeDetailUtils from "./utils";
import {
  Loader, ModelDetail, PageHeader, Table
} from "../../../../components/layout";
import Styles from "./styles";



type TribeModelData = {
  holder: ModelDetailData[] | null;
  reserve: ModelDetailData[] | null;
  current: ModelDetailData[] | null;
  card: ModelDetailData[] | null;
}

function TribeDetail() {
  const { tribeId } = useParams<URLParams>();
  const { getTribeAccount } = useTribe();
  const { listTribeExternalTxns } = useTribeExternalTxns();
  const [loading, setLoading] = useState<boolean>(true);
  const [tribe, setTribe] = useState<Tribe>();
  const [modelData, setModelData] = useState<TribeModelData>();
  const [txnData, setTxnData] = useState<
    PaginatedResponse<TribeExternalTxn>
  >();
  const {
    buildHolderData, buildReserveAccData,
    buildCurrentData, buildCardData, tribeTxnColumns
  } = useTribeDetailUtils();


  /**********************************/
  /** Retribe Tribe Account Details */
  const retrieveTribe = useCallback(async (tribeId: string) => {
    setLoading(true);
    try {
      const tribe = await getTribeAccount(tribeId)
      setTribe(tribe);
      setModelData({
        holder: buildHolderData(tribe),
        reserve: buildReserveAccData(tribe),
        current: buildCurrentData(tribe),
        card: buildCardData(tribe)
      });
      const params = { userId: tribe.userId };
      const txns = await listTribeExternalTxns(params);
      setTxnData(txns);
    } catch { }
    finally {
      setLoading(false);
    }
  }, [])


  useEffect(() => {
    if (!tribeId) return;
    retrieveTribe(tribeId);
  }, [tribeId, retrieveTribe])



  return (
    <Styles className="root-content">
      <PageHeader
        className="username-link"
        title={tribe
          ? tribe.user.username
          : <Spinner />
        }
      />
      <div className="page-wrapper">
        {!tribe ? (
          <Loader />
        ) : (
          <>
            <div className="flex wrap">
              {/* Holder */}
              {modelData?.holder ? (
                <ModelDetail
                  title="Holder Acc."
                  className="flex-fill"
                  data={modelData.holder}
                />
              ) : null}

              <div className="flex column flex-fill">
                {/* Reserve Account */}
                {modelData?.reserve ? (
                  <ModelDetail
                    title="Reserve Acc."
                    data={modelData.reserve}
                  />
                ) : null}
                {/* Current Account */}
                {modelData?.current ? (
                  <ModelDetail
                    title="Current Acc."
                    data={modelData.current}
                  />
                ) : null}
                {/* Card */}
                {modelData?.card ? (
                  <ModelDetail
                    title="Card Acc."
                    data={modelData.card}
                  />
                ) : null}
              </div>
            </div>

            <Table
              {...txnData}
              loading={loading}
              columns={tribeTxnColumns}
              noData={{
                title: "No Transactions"
              }}
            />
          </>
        )}
      </div>
    </Styles>
  );
}

export default TribeDetail;