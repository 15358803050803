import { Link } from "react-router-dom";
import { MouseEvent } from "react";
import moment from "moment";

import { dateAndTimeFormat, defaultDateFormat } from "../../../../utils/dates";
import { getVerboseInterval } from "../../../../requests/subscriptions/utils";
import { ModelDetailData } from "../../../../components/layout/ModelDetail";
import { useAppModal } from "../../../../components/layout/AppModal/utils";
import useSubscriptions from "../../../../requests/subscriptions";
import { accountPaths } from "../../../../routes/accounts/paths";
import { optionalString } from "../../../../utils/strings";
import useAppActions from "../../../../redux/actions/app";
import {
  SubscriptionDiscount
} from "../../../../requests/subscriptionDiscount/types";
import {
  RecurringInfo, Subscription
} from "../../../../requests/subscriptions/types";



export const useSubscriptionDetailUtils = () => {
  const { cancelSubscription } = useSubscriptions();
  const { setAppModal, closeModal } = useAppModal();
  const { setAppLoading } = useAppActions();



  /****************************/
  /** Build Subscription Data */
  const buildSubscriptionData = (subscription: Subscription) => {
    const {
      id, recurringId, billingAttempts, manager, status,
      freeTrialStart, freeTrialEnd, periodEnd, planPrice,
      lastBillingPeriod, nextBillingPeriod
    } = subscription;
    const modelData: ModelDetailData[] = [
      {
        name: "Plan",
        description: (
          planPrice ? (
            `${planPrice.plan.name}${" "
            }(${getVerboseInterval(planPrice.interval)})`
          ) : "Free"
        )
      },
      {
        name: "Subscription ID",
        description: id
      },
      {
        name: "ECommPay Recurring ID",
        description: optionalString(recurringId)
      },
      {
        name: "Status",
        description: (
          <div className={`status ${status}`}>
            {status}
          </div>
        )
      },
      {
        name: "Free Trial Start",
        description: (
          moment(freeTrialStart).isValid()
            ? moment(freeTrialStart).format(dateAndTimeFormat)
            : "-"
        )
      },
      {
        name: "Free Trial End",
        description: (
          moment(freeTrialEnd).isValid()
            ? moment(freeTrialEnd).format(dateAndTimeFormat)
            : "-"
        )
      },
      {
        name: "Failed Billing Attempts",
        description: billingAttempts
      },
      {
        name: "Last Billing Attempt",
        description: (
          moment(lastBillingPeriod).isValid()
            ? moment(lastBillingPeriod).format(dateAndTimeFormat)
            : "-"
        )
      },
      {
        name: "Next Billing Attempt",
        description: (
          moment(nextBillingPeriod).isValid()
            ? moment(nextBillingPeriod).format(dateAndTimeFormat)
            : "-"
        )
      },
      {
        name: "Expires On",
        description: (
          moment(periodEnd).isValid()
            ? moment(periodEnd).format(dateAndTimeFormat)
            : "-"
        )
      },
      {
        name: "Current Manager",
        description: (
          <Link
            className="link underline"
            to={accountPaths.userDetail(manager.id)}
          >
            {manager.username}
          </Link>
        )
      }
    ]

    return modelData;
  }


  /************************/
  /** Build Ecommpay Data */
  const buildEcommpayData = (recurringInfo?: RecurringInfo) => {
    if (!recurringInfo) return [];
    const { id, valid_thru, status, last_payment_at } = recurringInfo.recurring;
    const modelData: ModelDetailData[] = [
      {
        name: "ID",
        description: id
      },
      {
        name: "Payment Method Expiry",
        description: moment(valid_thru).format(defaultDateFormat)
      },
      {
        name: "Last Payment At",
        description: (
          moment(last_payment_at).isValid()
            ? moment(last_payment_at).format(defaultDateFormat)
            : "-"
        )
      },
      {
        name: "Status",
        description: (
          <div className={`status ${status}`}>
            {status}
          </div>
        )
      }
    ]

    return modelData;
  }


  /************************/
  /** Build Discount Data */
  const buildDiscountData = (discounts?: SubscriptionDiscount[]) => {
    if (!discounts) return [];
    const data = discounts.map((discount) => {
      const { active, periodStart, periodEnd } = discount;
      const status = active ? "active" : "expired";
      const modelData: ModelDetailData[] = [
        {
          name: "Status",
          description: (
            <div className={`status ${status}`}>
              {status}
            </div>
          )
        },
        {
          name: "Valid From",
          description: (
            moment(periodStart).isValid()
              ? moment(periodStart).format(defaultDateFormat)
              : "-"
          )
        },
        {
          name: "Valid To",
          description: (
            moment(periodEnd).isValid()
              ? moment(periodEnd).format(defaultDateFormat)
              : "-"
          )
        }
      ]

      return modelData;
    });

    return data;
  }


  /******************************/
  /** Cancel User's Subscription */
  const proceedWithCancellation = async (
    e: MouseEvent,
    subscription: Subscription
  ) => {
    e.preventDefault();
    try {
      closeModal();
      setAppLoading(true);
      await cancelSubscription(subscription.id);
      setAppModal({
        title: "Subscription Cancelled",
        children: (
          <div>
            {subscription.manager.firstName}'s subscription has{" "
            }been successfully cancelled.
          </div>
        )
      });
    } catch { }
    finally {
      setAppLoading(false);
      window.location.reload();
    }
  }


  /************************************************/
  /** Confirm Cancellation of User's Subscription */
  const confirmCancellation = (
    e: MouseEvent,
    subscription?: Subscription
  ) => {
    e.preventDefault();
    if (!subscription) return null;
    setAppModal({
      title: "Cancel Subscription",
      children: (
        <div className="grey-text">
          Are you sure you want to cancel{" "
          }{subscription.manager.firstName}'s subscription?
          <br /><br />
          They and their family would lose access to all Paid{" "
          }features of the SONA app at the end of the current billing cycle.
        </div>
      ),
      controls: (
        <div className="modal-controls">
          <button
            className="button white-black"
            onClick={closeModal}
          >
            Cancel
          </button>
          <button
            className="button"
            onClick={(e) => proceedWithCancellation(e, subscription)}
          >
            Yes, Cancel
          </button>
        </div>
      )
    });
  }


  return {
    buildSubscriptionData,
    buildEcommpayData,
    buildDiscountData,
    confirmCancellation
  }
}