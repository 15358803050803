import { SelectFieldOption } from "../../components/forms/SelectField";

export type PlanType =
  | "free"
  | "individual"
  | "standard"
  | "family"
  | "familyPlus";

type PlanFeature = {
  id: number;
  description: string;
}

export type Plan = {
  id: number;
  active: boolean;
  name: string;
  description: string;
  planType: PlanType;
  featureTitle: string;
  region: string;
  planFeatures: PlanFeature[];
  planPrices: PlanPrice[];
  maxUsers: number;
  maxParents: number;
  maxLearners: number;
  maxCards: number
  createdAt: Date;
  updatedAt: Date;
}

export type PlanPriceInterval =
  | "month"
  | "year";

export type PlanPrice = {
  id: number;
  currency: string;
  amount: number;
  oldAmount: number;
  interval: PlanPriceInterval;
  store: "sona" | "playStore" | "appStore";
  plan: Plan;
  createdAt: Date;
  updatedAt: Date;
}


interface PlanTypeOption extends SelectFieldOption {
  value: PlanType | "";
}

export const PlanTypes: PlanTypeOption[] = [
  {
    value: "",
    label: "All Types"
  },
  {
    value: "free",
    label: "Free"
  },
  {
    value: "individual",
    label: "Individual"
  },
  {
    value: "standard",
    label: "Standard"
  },
  {
    value: "family",
    label: "Family"
  },
  {
    value: "familyPlus",
    label: "Family Plus"
  }
]

interface PlanIntervalOption extends SelectFieldOption {
  value: PlanPriceInterval | "";
}

export const PlanIntervals: SelectFieldOption[] = [
  {
    value: "",
    label: "All Intervals"
  },
  {
    value: "month",
    label: "Monthly"
  },
  {
    value: "year",
    label: "Yearly"
  }
]