import { Link, useParams } from "react-router-dom";
import { useEffect, useState } from "react";

import { Loader, ModelDetail, PageHeader } from "../../../components/layout";
import { ModelDetailData } from "../../../components/layout/ModelDetail";
import { emailPaths } from "../../../routes/emails/paths";
import { URLParams } from "../../../constants";
import Styles from "./styles";
import { SONAEvent } from "../../../requests/events/types";
import { useEventDetailUtils } from "./utils";
import useEvents from "../../../requests/events";



function EventDetail() {
  const { eventId } = useParams<URLParams>();
  const { getEvent } = useEvents();
  const [loading, setLoading] = useState<boolean>(true);
  const [event, setEvent] = useState<SONAEvent>();
  const [eventData, setEventData] = useState<ModelDetailData[]>([]);
  const { buildEventData } = useEventDetailUtils();


  /**********************/
  /** Get Email Details */
  const retrieveEmail = async (id: number | string) => {
    setLoading(true);
    try {
      const event = await getEvent(id);
      setEvent(event);
      setEventData(buildEventData(event));
    } catch { }
    finally {
      setLoading(false);
    }
  }


  useEffect(() => {
    if (!eventId) return;
    retrieveEmail(eventId);
  }, [eventId])



  return (
    <Styles className="root-content">
      {/* Header */}
      <PageHeader />

      {/* Main Content */}
      <div className="page-wrapper">
        {loading ? (
          <Loader />
        ) : (
          <ModelDetail
            data={eventData}
            title={event ? (
              `EVENT: ${event.name}`
            ) : null}
          />
        )}
      </div>
    </Styles>
  );
}

export default EventDetail;