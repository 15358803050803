import axios from "axios";

import { ErrorResponse, PaginatedResponse } from "../../types";
import { SONAEvent } from "./types";



/*  ####################### */
/** ## EVENT API REQUESTS # */
/*  ####################### */
const useEvents = () => {
  const tempFunnel = () => {
    return new Promise<any>((resolve, reject) => {
      axios({
        url: `${process.env.REACT_APP_SONA_AUTH_API_URL
          }/v2/events/temp-funnel`
      }).then((res) => {
        const resData = res.data;
        resolve(resData);
      }).catch((error) => {
        reject(error as ErrorResponse);
      });
    });
  }

  /****************/
  /** List Events */
  const listEvents = (params: any) => {
    return new Promise<PaginatedResponse<SONAEvent>>((resolve, reject) => {
      axios({
        url: `${process.env.REACT_APP_SONA_AUTH_API_URL
          }/v2/events/`,
        params
      }).then((res) => {
        resolve(res.data as PaginatedResponse<SONAEvent>);
      }).catch((error) => {
        reject(error as ErrorResponse);
      });
    });
  }

  /***************/
  /** Get Event */
  const getEvent = (id: number | string) => {
    return new Promise<SONAEvent>((resolve, reject) => {
      axios({
        url: `${process.env.REACT_APP_SONA_AUTH_API_URL
          }/v2/events/${id}`
      }).then((res) => {
        resolve(res.data as SONAEvent);
      }).catch((error) => {
        reject(error as ErrorResponse);
      });
    });
  }


  return {
    tempFunnel,
    listEvents,
    getEvent
  }
}

export default useEvents;